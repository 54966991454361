@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials$doc-font-size: 5px !default;

.sidebar img {
  width: 40%;
}
.sidebar p {
  margin-top: 0;
  margin-bottom: 0;
}
.nav__list {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.sidebar {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .sidebar img {
    width: 45%;
  }
}

.rightmenu nav ul li {
  font-size: 0.75em;
}
.rightmenu-archive {
  position: absolute;
  right: 220px;
  width: 200px;
  top: 4.5em;
  z-index: 10;
  left: 52.7em;
}

.rightmenu-single {
  top: -3.3em;
  left: 5em;
}

div.social {
  float: right;
  margin-top: 0.5em;
}

.social img {
  height: 1em;
}

.social a {
  text-decoration: none;
}
